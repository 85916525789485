import React from "react"
import TermsAndConditions1 from "../sections/terms-and-condition/terms-and-condition-1"

const Terms = () => {
  return (
    <div>
      <TermsAndConditions1 />
    </div>
  )
}

export default Terms
